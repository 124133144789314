import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../../notifications";
import { EditorField, FormCollapsible, DateInput, StateSelect, TextInput, Select } from "components/forms";
import { createAccountCarrierAppointment, editAccountCarrierAppointment } from "actions";
import { activeAccountIdSelector, activeCarrierAppointmentSelector, managementChoicesSelector } from "reducers";
import { partial, prepareMultipleSelect } from "utils";
import WideSaveButton from "components/buttons/WideSaveButton";
import { appointmentStatusChoices, linesOfBusinessChoices } from "constants.js";
import { usStatesChoices } from "options";

const CarrierAppointmentForm = ({ activeCarrierAppointment, ...props }) => {
  let { carrier_id, code_number, start_date, end_date, states, lines_of_business, appointment_status, notes } =
    activeCarrierAppointment;

  let [commaSeparatedStates, setCommaSeparatedStates] = React.useState();
  const handleStatesChange = selectedOptions => {
    if (selectedOptions) {
      setCommaSeparatedStates(selectedOptions.map(o => o.value).join(","));
    } else {
      setCommaSeparatedStates(undefined);
    }
  };

  let [linesOfBusiness, setLinesOfBusiness] = React.useState();
  const handleLinesOfBusinessChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedValues = selectedOptions.map(o => o.value).join(",");
      setLinesOfBusiness(commaSeparatedValues);
    } else {
      setLinesOfBusiness(undefined);
    }
  };

  let states_initial = prepareMultipleSelect("states", usStatesChoices.getChoices(), activeCarrierAppointment);
  let lines_of_business_initial = prepareMultipleSelect(
    "lines_of_business",
    linesOfBusinessChoices,
    activeCarrierAppointment,
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        carrier_id,
        code_number,
        start_date,
        end_date,
        states,
        lines_of_business,
        appointment_status,
        notes,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = !(activeCarrierAppointment && activeCarrierAppointment.id)
          ? props.createAccountCarrierAppointment
          : partial(props.editAccountCarrierAppointment, activeCarrierAppointment.id);

        values.account = props.activeAccountId;
        values.states = commaSeparatedStates;
        values.lines_of_business = linesOfBusiness;

        try {
          await method(values);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Carrier Appointment");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Carrier Appointment Detail">
            <Form.Row>
              <Select
                label="Carrier"
                name="carrier_id"
                placeholder="Select Carrier"
                options={props.carrierChoices.getChoices()}
              />
              <TextInput label="Carrier Code Number" name="code_number" />
              <DateInput label="Start Date" name="start_date" />
              <DateInput label="End Date" name="end_date" />
            </Form.Row>
            <Form.Row>
              <StateSelect
                label="States"
                name="states"
                placeholder="Select States"
                isMulti={true}
                onChange={handleStatesChange}
                defaultValue={states_initial}
              />
              <Select
                label="Lines of Business"
                name="lines_of_business"
                placeholder="Select Lines of Business"
                options={linesOfBusinessChoices}
                isMulti={true}
                onChange={handleLinesOfBusinessChange}
                defaultValue={lines_of_business_initial}
              />
              <Select label="Appointment Status" name="appointment_status" options={appointmentStatusChoices} />
            </Form.Row>
            <Form.Row>
              <EditorField label="Notes" name="notes" />
            </Form.Row>
            <WideSaveButton submitRef={props.submitRef} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  console.log(activeCarrierAppointmentSelector(state) || {});
  console.log(isEmpty(activeCarrierAppointmentSelector(state) || {}));

  return {
    activeAccountId: activeAccountIdSelector(state),
    activeCarrierAppointment: activeCarrierAppointmentSelector(state) || {},
    carrierChoices: choices.carriers,
  };
};

export default connect(mapStateToProps, {
  createAccountCarrierAppointment,
  editAccountCarrierAppointment,
})(CarrierAppointmentForm);
